import React, { useContext, useState, useEffect, useMemo, createContext } from "react"
import Firebase from "./Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore"

export const FirebaseContext = createContext({});

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error(`useFirebase must be used within a FirebaseProvider`);
  }
  return context;
} 


export const FirebaseProvider = ({ children }) => {

  const [firebase, setFirebase] = useState(null);
  const [usuario, setUsuario] = useState({loaded:false, uid: null, email: null, displayName: null});
  const [perfilUsuario, setPerfilUsuario] = useState({loaded:false, data:{}});

  useEffect(() => {

    if (!firebase) {
      if (process.env.NODE_ENV === 'development') console.log("INICIALIZADO FIREBASE DESDE PROVIDER")
      setFirebase(new Firebase())
      return;
    }
  });

  useEffect(() => {

    if (firebase) {
      if (process.env.NODE_ENV === 'development') console.log("SETTING USER")
      const auth = getAuth();
      //const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      getAuth(firebase.app).onAuthStateChanged(async function(user) {
        if (user) {
          setUsuario({
            loaded: true,
            uid: user.uid,
            email: user.email,
            displayName: user.displayName
          })
          if (process.env.NODE_ENV === 'development') console.log("USUARIO=", user.uid)

          const unsubscribeSnapshot = onSnapshot(doc(getFirestore(), "users", user.uid), (doc) => {
            setPerfilUsuario({
              loaded: true,
              data: doc.data()
            })
            if (process.env.NODE_ENV === 'development') console.log("Perfil de usuario=", doc.data())
          });

          /*/const userProfile = await getDoc(doc(getFirestore(firebase.app), "users", user.uid))
          console.log("Perfil de usuario=", userProfile.data())
          setPerfilUsuario({
            loaded: true,
            data: userProfile.data()
          })*/
          
          if (process.env.NODE_ENV === 'development') console.log("USUARIO CHANGED= ", JSON.stringify(user))
          //return () => unsubscribeSnapshot();
          
        } else {
          setUsuario({loaded:false, uid: null, email: null, displayName: null})
        }
      }); 

      //return () => unsubscribeAuth();
    }
  }, [firebase]);

  const value = useMemo(() => {
    return { firebase, usuario, setUsuario, perfilUsuario, setPerfilUsuario};
  }, [firebase, usuario, perfilUsuario]);

  if (!firebase) {
      return "";

  } else {
    return (
        <FirebaseContext.Provider
            value={value}
        >
            {children}
        </FirebaseContext.Provider>
    );
  }
};