module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://micuentodigital.es"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mi Cuento Digital","short_name":"Cuento Digital","description":"Crea cuentos personalizados y únicos para ti y tus hijos. Con ilustraciones en cuestión de minutos.","lang":"es","display":"standalone","icon":"src/images/MiCuentoDigital.png","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44181c95dbdc85d1b64bf3f4b3f2e86a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://micuentodigital.es","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/MiCuentoDigital.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44181c95dbdc85d1b64bf3f4b3f2e86a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KMV3YB4KSR"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
