exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-como-funciona-js": () => import("./../../../src/pages/como-funciona.js" /* webpackChunkName: "component---src-pages-como-funciona-js" */),
  "component---src-pages-crear-cuentos-js": () => import("./../../../src/pages/crear-cuentos.js" /* webpackChunkName: "component---src-pages-crear-cuentos-js" */),
  "component---src-pages-cuentos-infantiles-de-2-a-6-[slug]-js": () => import("./../../../src/pages/cuentos-infantiles/de-2-a-6/[slug].js" /* webpackChunkName: "component---src-pages-cuentos-infantiles-de-2-a-6-[slug]-js" */),
  "component---src-pages-cuentos-infantiles-de-2-a-6-tale-slug-js": () => import("./../../../src/pages/cuentos-infantiles/de-2-a-6/{Tale.slug}.js" /* webpackChunkName: "component---src-pages-cuentos-infantiles-de-2-a-6-tale-slug-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-politica-de-privacidad-js": () => import("./../../../src/pages/legal/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-js" */),
  "component---src-pages-legal-terminos-y-condiciones-js": () => import("./../../../src/pages/legal/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-js" */),
  "component---src-pages-mi-cuenta-iniciar-sesion-js": () => import("./../../../src/pages/mi-cuenta/iniciar-sesion.js" /* webpackChunkName: "component---src-pages-mi-cuenta-iniciar-sesion-js" */),
  "component---src-pages-mi-cuenta-mi-biblioteca-js": () => import("./../../../src/pages/mi-cuenta/mi-biblioteca.js" /* webpackChunkName: "component---src-pages-mi-cuenta-mi-biblioteca-js" */),
  "component---src-pages-mi-cuenta-mi-perfil-js": () => import("./../../../src/pages/mi-cuenta/mi-perfil.js" /* webpackChunkName: "component---src-pages-mi-cuenta-mi-perfil-js" */),
  "component---src-pages-mi-cuenta-recordar-contrasena-js": () => import("./../../../src/pages/mi-cuenta/recordar-contrasena.js" /* webpackChunkName: "component---src-pages-mi-cuenta-recordar-contrasena-js" */),
  "component---src-pages-mi-cuenta-registrarse-js": () => import("./../../../src/pages/mi-cuenta/registrarse.js" /* webpackChunkName: "component---src-pages-mi-cuenta-registrarse-js" */),
  "component---src-pages-mi-cuenta-subscripcion-js": () => import("./../../../src/pages/mi-cuenta/subscripcion.js" /* webpackChunkName: "component---src-pages-mi-cuenta-subscripcion-js" */),
  "component---src-pages-pago-erroneo-js": () => import("./../../../src/pages/pago-erroneo.js" /* webpackChunkName: "component---src-pages-pago-erroneo-js" */),
  "component---src-pages-pago-exitoso-js": () => import("./../../../src/pages/pago-exitoso.js" /* webpackChunkName: "component---src-pages-pago-exitoso-js" */),
  "component---src-pages-precios-js": () => import("./../../../src/pages/precios.js" /* webpackChunkName: "component---src-pages-precios-js" */),
  "component---src-pages-versiones-js": () => import("./../../../src/pages/versiones.js" /* webpackChunkName: "component---src-pages-versiones-js" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-el-impacto-de-los-valores-que-transmiten-los-cuentos-infantiles-en-los-ninos-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202304.El impacto de los valores que transmiten los cuentos infantiles en los niños/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-el-impacto-de-los-valores-que-transmiten-los-cuentos-infantiles-en-los-ninos-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-la-evolucion-de-los-cuentos-infantiles-a-traves-del-tiempo-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202304La evolución de los cuentos infantiles a través del tiempo/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-la-evolucion-de-los-cuentos-infantiles-a-traves-del-tiempo-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-la-importancia-de-la-lectura-en-la-infancia-y-como-fomentarla-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202304.La importancia de la lectura en la infancia y cómo fomentarla/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-la-importancia-de-la-lectura-en-la-infancia-y-como-fomentarla-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-las-diferencias-entre-los-cuentos-clasicos-y-los-cuentos-modernos-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202304.Las diferencias entre los cuentos clásicos y los cuentos modernos./post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202304-las-diferencias-entre-los-cuentos-clasicos-y-los-cuentos-modernos-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202308-como-escribir-cuentos-infantiles-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202308.Como escribir cuentos infantiles/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202308-como-escribir-cuentos-infantiles-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202308-sabes-cual-es-el-secreto-de-los-cuentos-infantiles-para-dormir-te-lo-contamos-aqui-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202308.Sabes cuál es el secreto de los cuentos infantiles para dormir Te lo contamos aquí/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202308-sabes-cual-es-el-secreto-de-los-cuentos-infantiles-para-dormir-te-lo-contamos-aqui-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-10-libros-infantiles-en-ingles-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.10 Libros infantiles en ingles/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-10-libros-infantiles-en-ingles-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-cortos-infantiles-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Cuentos cortos infantiles/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-cortos-infantiles-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-infantiles-cortos-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Cuentos Infantiles Cortos/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-infantiles-cortos-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-para-aprender-a-leer-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Cuentos para aprender a leer/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-cuentos-para-aprender-a-leer-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-libros-infantiles-personalizados-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Libros Infantiles Personalizados/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-libros-infantiles-personalizados-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-los-cuentos-clasicos-que-han-dejado-huella-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Los cuentos clasicos que han dejado huella/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-los-cuentos-clasicos-que-han-dejado-huella-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-rincon-de-lectura-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202309.Rincon de Lectura/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202309-rincon-de-lectura-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-d-pa-p-el-encanto-de-alicia-a-traves-del-espejo-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202310. DPaP: El Encanto de Alicia a través del Espejo/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-d-pa-p-el-encanto-de-alicia-a-traves-del-espejo-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-d-pa-p-libro-de-la-selva-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202310. DPaP: Libro de la Selva/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-d-pa-p-libro-de-la-selva-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-inteligencia-artificial-imagenes-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202310.Inteligencia Artificial Imagenes/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-inteligencia-artificial-imagenes-post-mdx" */),
  "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-juegos-de-palabras-post-mdx": () => import("./../../../src/templates/articulo-template.js?__contentFilePath=/home/runner/work/website/website/website/src/articulos/202310.Juegos de palabras/post.mdx" /* webpackChunkName: "component---src-templates-articulo-template-js-content-file-path-src-articulos-202310-juegos-de-palabras-post-mdx" */),
  "component---src-templates-galeria-template-js": () => import("./../../../src/templates/galeria-template.js" /* webpackChunkName: "component---src-templates-galeria-template-js" */)
}

